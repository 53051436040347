import React from 'react';

const MainComponent = () => {
  return (
    <main>
      {/* First container */}
      <div className="container-fluid background-z">
        <div className="container">
          {/* Section: Possibilities */}
          <section className="py-4">
            {/* Section heading */}
            <h2 className="text-center text-uppercase font-weight-bold mt-5 pt-4 spacing wow fadeIn" data-wow-delay="0.2s">
              <strong>Transport</strong>
            </h2>
            {/* Section description */}
            <p className="text-center text-uppercase font-weight-bold grey-text mb-5 pb-3 wow fadeIn" data-wow-delay="0.2s">
              <i className="fas fa-square red-text-2 mr-2" aria-hidden="true"></i> Lorem ipsum dolor sit amet
            </p>
            {/* First row */}
            <div className="row mb-5 wow fadeIn" data-wow-delay="0.4s">
              <div className="col-lg-3 col-md-6 text-center pt-1">
                <div className="icon-area">
                  <div className="circle-icon">
                    <i className="fas fa-car"></i>
                  </div>
                  <br />
                  <strong>ROAD FREIGHT</strong>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting let. Lorem Ipsum has been the industry.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center pt-1">
                <div className="icon-area">
                  <div className="circle-icon">
                    <i className="fas fa-plane"></i>
                  </div>
                  <br />
                  <strong>AIR FREIGHT</strong>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting let. Lorem Ipsum has been the industry.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center pt-1">
                <div className="icon-area">
                  <div className="circle-icon">
                    <i className="fas fa-train"></i>
                  </div>
                  <br />
                  <strong>TRAIN FREIGHT</strong>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting let. Lorem Ipsum has been the industry.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center pt-1">
                <div className="icon-area">
                  <div className="circle-icon">
                    <i className="fas fa-ship"></i>
                  </div>
                  <br />
                  <strong>OCEAN FREIGHT</strong>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting let. Lorem Ipsum has been the industry.</p>
                </div>
              </div>
            </div>
            {/* /First row */}
          </section>
          {/* /Section: Possibilities */}
        </div>
      </div>
      {/* /First container */}

      {/* Second container */}
      <div className="container">
        {/* Section: About */}
        <section id="about" className="about my-5">
          {/* Section heading */}
          <h2 className="text-center text-uppercase font-weight-bold mt-5 pt-4 pt-4 spacing wow fadeIn" data-wow-delay="0.2s">
            <strong>About us</strong>
          </h2>
          {/* Section description */}
          <p className="text-center text-uppercase font-weight-bold grey-text mb-5 pb-3 wow fadeIn" data-wow-delay="0.2s">
            <i className="fas fa-square red-text-2 mr-2" aria-hidden="true"></i> Lorem ipsum dolor sit amet
          </p>
          {/* First row */}
          <div className="row">
            {/* First column */}
            <div className="col-xl-5 col-lg-6 pb-1 wow fadeIn" data-wow-delay="0.4s">
              {/* Description */}
              <p align="justify">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo animi soluta ratione quisquam, dicta ab cupiditate iure eaque? Repellendus voluptatum, magni impedit eaque animi maxime.
              </p>
              <p align="justify">
                Nemo animi soluta ratione quisquam, dicta ab cupiditate iure eaque? Repellendus voluptatum, magni impedit eaque delectus, beatae maxime temporibus maiores quibusdam quasi rem magnam ad perferendis iusto sint tempora.
              </p>
              <ul>
                <li>Nemo animi soluta ratione</li>
                <li>Beatae maxime temporibus</li>
                <li>Consectetur adipisicing elit</li>
                <li>Repellendus voluptatum, impedit</li>
              </ul>
            </div>
            {/* /First column */}
            {/* Column */}
            <div className="col-xl-6 ml-lg-auto col-lg-6 mb-5 wow fadeIn" data-wow-delay="0.4s">
              {/* Image */}
              <img src="https://mdbootstrap.com/img/Photos/Horizontal/Technology/4-col/img%20%288%29.jpg" className="img-fluid rounded z-depth-1" alt="My photo" />
            </div>
            {/* /Column */}
          </div>
          {/* /First row */}
        </section>
        {/* /Section: About */}
        <hr />
        {/* Section: Services */}
        <section className="mt-5 mb-3" id="services">
          {/* Grid row */}
          <div className="row mt-5 pt-5 pb-3">
            {/* Grid column */}
            <div className="col-lg-3 col-md-6">
              {/* Section heading */}
              <h4 className="font-weight-bold mb-4 wow fadeIn" data-wow-delay="0.2s">
                <strong className="red-text-2 font-weight-bold">
                  <i className="fas fa-square red-text-2 mr-2" aria-hidden="true"></i>
                </strong>
                Our Services
              </h4>
              {/* Description */}
              <p align="" className="dark-grey-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo animi soluta ratione quisquam, dicta ab cupiditate iure eaque? Repellendus voluptatum, magni impedit eaque animi maxime. Soluta ratione quisquam, dicta ab cupiditate iure eaque? Repellendus voluptatum, magni impedit.
              </p>
            </div>
            {/* /Grid column */}
            {/* Grid column */}
            <div className="col-lg-3 col-md-6 mb-4">
              <img src="https://mdbootstrap.com/img/Photos/Others/images/31.jpg" className="img-fluid z-depth-1 rounded" alt="sample image" />
              {/* Section heading */}
              <h5 className="font-weight-bold dark-grey-text mt-4 mb-3 wow fadeIn" data-wow-delay="0.2s">Lorem ipsum</h5>
              {/* Description */}
              <p align="justify" className="font-small">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo animi soluta ratione quisquam, dicta ab cupiditate iure eaque.
              </p>
              <a href="#">
                <h6 className="font-weight-bold dark-grey-text font-small">
                  Read more
                  <i className="fas fa-long-arrow-alt-right ml-2" aria-hidden="true"></i>
                </h6>
              </a>
            </div>
            {/* /Grid column */}
            {/* Grid column */}
            <div className="col-lg-3 col-md-6">
              <img src="https://mdbootstrap.com/img/Photos/Others/images/32.jpg" className="img-fluid z-depth-1 rounded" alt="sample image" />
              {/* Section heading */}
              <h5 className="font-weight-bold dark-grey-text mt-4 mb-3 wow fadeIn" data-wow-delay="0.2s">Lorem ipsum</h5>
              {/* Description */}
              <p align="justify" className="font-small">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo animi soluta ratione quisquam, dicta ab cupiditate iure eaque.
              </p>
              <a href="#">
                <h6 className="font-weight-bold dark-grey-text font-small">
                  Read more
                  <i className="fas fa-long-arrow-alt-right ml-2" aria-hidden="true"></i>
                </h6>
              </a>
            </div>
            {/* /Grid column */}
            {/* Grid column */}
            <div className="col-lg-3 col-md-6">
              <img src="https://mdbootstrap.com/img/Photos/Others/images/30.jpg" className="img-fluid z-depth-1 rounded" alt="sample image" />
              {/* Section heading */}
              <h5 className="font-weight-bold dark-grey-text mt-4 mb-3 wow fadeIn" data-wow-delay="0.2s">Lorem ipsum</h5>
              {/* Description */}
              <p align="justify" className="font-small">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo animi soluta ratione quisquam, dicta ab cupiditate iure eaque.
              </p>
              <a href="#">
                <h6 className="font-weight-bold dark-grey-text font-small">
                  Read more
                  <i className="fas fa-long-arrow-alt-right ml-2" aria-hidden="true"></i>
                </h6>
              </a>
            </div>
            {/* /Grid column */}
          </div>
          {/* /Grid row */}
        </section>
        {/* /Section: Services */}
      </div>
      {/* /Second container */}
    </main>
  );
};

export default MainComponent;
