import React, { useRef, useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Carousel, Card } from 'react-bootstrap';
import img6 from './images/6.png';
import img11 from './images/11.png';
import img15 from './images/15.png';
import fertilizer from './images/fertilizer.png';
import generalSupplies from './images/general supplies.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import WOW from 'wowjs';

const OurServices = () => {
    useEffect(() => {
        new WOW.WOW({
          live:false
        }).init();
          }, [])

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const transportationImages = [img6, img11, img15];
    const generalSuppliesImage = generalSupplies;
    const fertilizerImage = fertilizer;

    const cardStyle = {
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)', // 3D shadow effect
        transform: 'translateY(-5px)', // Lift the card
        transition: 'transform 0.2s, box-shadow 0.2s',
    };

    const cardHoverStyle = {
        transform: 'translateY(-10px)', // Further lift on hover
        boxShadow: '0 15px 30px rgba(0, 0, 0, 0.3)', // Deeper shadow on hover
    };

    const styles = `
        .card-overlay {
            position: relative;
            overflow: hidden;
        }

        .card-overlay img {
            display: block;
            width: 100%;
            height: auto;
        }

        .card-overlay .card-body {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.7);
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            transition: background 0.3s ease, opacity 0.3s ease;
        }

        .card-overlay:hover .card-body {
            background: rgba(0, 0, 0, 0.9);
            color: white;
        }

        .carousel-control-next,
        .carousel-control-prev {
            filter: invert(1);
        }

        .carousel-indicators li {
            background-color: black;
        }
    `;

    return (
        <Container className="mt-5 pt-5">
            <style>{styles}</style>
            <h1 className="mb-4 text-center pt-0 text-uppercase font-weight-bold orange-text">Our Services</h1>

            {/* Transportation Services */}
            <section className="mb-5 wow fadeInUp" data-wow-delay="0.5s">
                <Row className="align-items-stretch">
                    <Col md={6} className="mb-3 d-flex">
                        <Card className="shadow-sm flex-fill" style={cardStyle} onMouseEnter={(e) => e.currentTarget.style = { ...cardStyle, ...cardHoverStyle }} onMouseLeave={(e) => e.currentTarget.style = cardStyle}>
                            <Card.Body className='bg-dark'>
                                <Card.Title className='orange-text'>Transportation Services</Card.Title>
                                <Card.Text className='white-text'>
                                    Our transportation services include tipper truck services for carrying a variety of materials such as building sand, gravel, crushed stone, topsoil, coal, and other bulk materials. We also offer international and local haulage services, ensuring the safe and timely delivery of goods within Zambia and beyond. Additionally, we provide local and international fuel transportation and delivery services.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-3 d-flex">
                        <Carousel activeIndex={index} onSelect={handleSelect} interval={3000} pause={false} indicators={true} nextIcon={<FontAwesomeIcon icon={faChevronRight} />} prevIcon={<FontAwesomeIcon icon={faChevronLeft} />} fade className="flex-fill">
                            {transportationImages.map((image, idx) => (
                                <Carousel.Item key={idx}>
                                    <img src={image} className="d-block w-100" alt={`Transportation Service ${idx + 1}`} />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Col>
                </Row>
            </section>

            {/* General Supplies and Fertilizer Sales */}
            <section className="mb-5">
                <Row className="align-items-stretch">
                    <Col md={6} className="mb-3 d-flex wow fadeInDown" data-wow-delay="0.7s">
                        <Card className="card-overlay shadow-sm flex-fill" style={cardStyle} onMouseEnter={(e) => e.currentTarget.style = { ...cardStyle, ...cardHoverStyle }} onMouseLeave={(e) => e.currentTarget.style = cardStyle}>
                            <img src={generalSuppliesImage} alt="General Supplies" />
                            <Card.Body>
                            <Row >
                                <Col md={12}>
                                <Card.Title className='orange-text'><strong>General Supplies</strong></Card.Title>
                                </Col>
                                <Col md={12}>
                                <strong> <hr /> </strong>
                                </Col>
                                <Col md={12} >
                                <Card.Text className='text-left white-text'>
                                    We supply a wide range of products, including mining chemicals and equipment, farming equipment, construction materials, industrial equipment, and office supplies. Our extensive inventory ensures that you have access to the essential items you need to keep your operations running smoothly.
                                </Card.Text>
                                </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-3 d-flex wow fadeInDown" data-wow-delay="0.9s">
                        <Card className="card-overlay shadow-sm flex-fill" style={cardStyle} onMouseEnter={(e) => e.currentTarget.style = { ...cardStyle, ...cardHoverStyle }} onMouseLeave={(e) => e.currentTarget.style = cardStyle}>
                            <img src={fertilizerImage} alt="Fertilizer Sales" />
                            <Card.Body>
                            <Row>
                                <Col md={12}>
                                <Card.Title className='orange-text'><strong>Fertilizer Sales</strong></Card.Title>
                                </Col>
                                <Col md={12}>
                                <strong> <hr /> </strong>
                                </Col>
                                <Col md={12} >
                                <Card.Text className='text-left white-text'>
                                    Enhance your soil fertility and boost your crop yields with our premium fertilizers at Lumbe Innovations Limited. Our range of fertilizers is designed to support the agricultural sector by helping farmers achieve better productivity and healthier crops.
                                </Card.Text>
                                </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </Container>
    );
};

export default OurServices;
