import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faPinterestP, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
//import './Footer.css'; // Assuming you have a CSS file for additional styles

const Footer = () => {
    return (
        <footer className="page-footer text-center text-md-left mt-5 elegant-color-dark">
            {/* Footer Links */}
            <div className="container mb-2">
                {/* First row */}
                <div className="row">
                    {/* First column */}
                    <div className="col-md-12 my-2 wow fadeIn" data-wow-delay="0.3s">
                        <div className="footer-socials flex-center">
                            {/* Facebook */}
                            <button type="button" className="btn-floating orange-text">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </button>
                            {/* Twitter */}
                            <button type="button" className="btn-floating orange-text">
                                <FontAwesomeIcon icon={faTwitter} />
                            </button>
                            {/* Instagram */}
                            <button type="button" className="btn-floating orange-text">
                                <FontAwesomeIcon icon={faInstagram} />
                            </button>
                        </div>
                    </div>
                    {/* First column */}
                </div>
                {/* First row */}
            </div>
            {/* Footer Links */}
            {/* Copyright */}
            <div className="footer-copyright py-3 text-center">
                <div className="container-fluid">
                    Lumbe Innovations Limited : <a href="https://www.kanchtech.com" target="_blank" rel="noopener noreferrer"> Powered By Kanch</a>
                </div>
            </div>
            {/* Copyright */}
        </footer>
    );
};

const App = () => {
    return (
        <div className="d-flex flex-column">
            <div className="flex-grow-1">
                {/* Your other components will go here */}
            </div>
            <Footer />
        </div>
    );
};

export default App;
