import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import logo from './images/logo.jpeg'; // adjust the path accordingly
import './css/NavigationBar.css'; // Import the CSS file

const NavigationBar = () => {
    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg" fixed="top" className="custom-navbar">
                <Container className='ml-4'>
                    <Navbar.Brand href="/">
                        <img src={logo} height="30" alt="Lumbe Innovations" /> 
                        <strong className='orange-text mr-5'> Lumbe Innovations Limited</strong>

                          {/* Add social media icons */}
                          <Nav.Item>
                                <Nav.Link href="#" className="nav-link-icon ml-2 orange-text">
                                    <FontAwesomeIcon icon={faFacebook} />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="#" className="nav-link-icon ml-2 orange-text">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="#" className="nav-link-icon ml-2 orange-text">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </Nav.Link>
                            </Nav.Item>

                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link href="/" className='white-text'>Home</Nav.Link>
                            <Nav.Link href="/why-choose-us" className='white-text'>Why Choose Us?</Nav.Link>
                            <Nav.Link href="/services" className='white-text'>Our Services</Nav.Link>
                            <Nav.Link href="/products" className='white-text'>Products and Services</Nav.Link>
                            <Nav.Link href="/about" className='white-text'>About Us</Nav.Link>
                            <Nav.Link href="/contact" className='white-text'>Contact Us</Nav.Link>
                          
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default NavigationBar;
