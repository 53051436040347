import React, { useRef, useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faClipboardCheck, faOilCan, faUsers } from '@fortawesome/free-solid-svg-icons';
import backgroundImg from './images/15.png'; // Adjust the path to your background image
import WOW from 'wowjs';

const WhyChooseUs = () => {

    useEffect(() => {
        new WOW.WOW({
          live:false
        }).init();
          }, [])

    const sectionStyle = {
        position: 'relative',
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh', // Ensure full viewport height
        padding: '80px 0', // Padding top and bottom for content spacing
        color: 'white', // Ensure text is white
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark overlay
        zIndex: 1, // Ensure overlay is below content
    };

    const contentStyle = {
        position: 'relative',
        zIndex: 2, // Ensure content is above overlay
    };

    const cardStyle = {
        transition: 'background 0.3s, color 0.3s',
        backgroundColor: 'rgba(0, 0, 0, 0.2)', // Slightly darker transparent background
        height: '100%', // Ensure cards fill up available space
        color: 'white',
    };

    const cardHoverStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Darker background on hover
        color: 'black', // Change text to black on hover
    };

    const styles = `
        .equal-height-cards .card {
            display: flex;
            flex-direction: column;
        }
        
        .equal-height-cards .card-body {
            flex-grow: 1;
        }

        .card-title, .card-text {
            color: white !important; // Ensure text is white by default
        }

        .main-heading {
            background-color: rgba(0, 0, 0, 0.0);
            display: inline-block; // Only apply background according to width of heading
            padding: 10px;
        }

        .card-hover:hover {
            background-color: rgba(0, 0, 0, 0.9) !important; // Darker background on hover
            color: black !important; // Change text to black on hover
        }
    `;

    return (
        <section className='mt-5' style={sectionStyle}>
            <div style={overlayStyle}></div>
            <style>{styles}</style>
            <Container className="py-0" style={contentStyle}>
                <Row>
                     <h1 className="text-center text-uppercase orange-text mb-5 main-heading wow fadeInDown" data-wow-delay="0.3s">
                        Why Choose Us?</h1>
                </Row>

                <Row className="equal-height-cards">
                    <Col md={6} className="mb-4 d-flex wow fadeInLeft" data-wow-delay="0.5s">
                        <Card 
                            className="shadow-sm flex-fill card-hover" 
                            style={cardStyle} 
                        >
                            <Card.Body className="d-flex flex-column text-white">
                                <Card.Title >
                                    <FontAwesomeIcon icon={faTruck} className="mr-2 orange-text" />
                                    <span className='orange-text'>Comprehensive Service Offering</span>
                                </Card.Title>
                                <hr />
                                <Card.Text>
                                    Lumbe Innovations Limited provides a wide range of services designed to meet the specific needs of our clients. From tipper truck services and fuel bulk sales to international and local haulage, we are equipped to handle all your logistical and supply chain requirements.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4 d-flex wow fadeInRight" data-wow-delay="0.8s">
                        <Card 
                            className="shadow-sm flex-fill card-hover" 
                            style={cardStyle} 
                        >
                            <Card.Body className="d-flex flex-column text-white">
                                <Card.Title>
                                    <FontAwesomeIcon icon={faClipboardCheck} className="mr-2 orange-text" />
                                  <span className='orange-text' >  Quality and Reliability </span>
                                </Card.Title>
                                <hr />
                                <Card.Text>
                                    We understand the importance of reliability and quality in every service we offer. Our team is committed to delivering services that exceed your expectations, ensuring that your projects run smoothly and efficiently.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="equal-height-cards">
                    <Col md={6} className="mb-4 d-flex wow fadeInLeft" data-wow-delay="0.11s">
                        <Card 
                            className="shadow-sm flex-fill card-hover" 
                            style={cardStyle} 
                        >
                            <Card.Body className="d-flex flex-column text-white">
                                <Card.Title>
                                    <FontAwesomeIcon icon={faOilCan} className="mr-2 orange-text" />
                                   <span className='orange-text'> Licensed Oil Marketing Company </span>
                                </Card.Title>
                                <hr />
                                <Card.Text>
                                    As a licensed Oil Marketing Company (OMC) accredited by the Energy Regulation Board (ERB), we are authorized to transport, sell, and store fuel products such as diesel, petrol, and kerosene. This certification guarantees that our fuel solutions meet the highest standards of safety and compliance.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4 d-flex wow fadeInRight" data-wow-delay="0.13s">
                        <Card 
                            className="shadow-sm flex-fill card-hover" 
                            style={cardStyle} 
                        >
                            <Card.Body className="d-flex flex-column text-white">
                                <Card.Title>
                                    <FontAwesomeIcon icon={faUsers} className="mr-2 orange-text" />
                                  <span className='orange-text'>  Expertise and Experience </span>
                                </Card.Title>
                                <hr />
                                <Card.Text>
                                    With years of experience in the industry, our team of professionals is well-equipped to provide expert advice and tailored solutions. We leverage our expertise to offer services that enhance your operations and contribute to your success.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default WhyChooseUs;
