import React, { useRef, useState, useEffect, useContext } from 'react'
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faMapMarkerAlt, faComment } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import WOW from 'wowjs';

const ContactUs = () => {
    useEffect(() => {
        new WOW.WOW({
          live:false
        }).init();
          }, [])

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        address: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://lumbe.kanchtech.com/server/contact.php', formData);
            alert('Form submitted successfully:', response.data);
            // Reset form fields after successful submission if needed
            setFormData({
                fullName: '',
                email: '',
                phone: '',
                address: '',
                message: ''
            });
        } catch (error) {
            alert('Internet Error when Contacting Us:');
            // Handle error state or display an error message
        }
    };

    return (
        <Container className="mt-5 pt-5" >
            <Row >
                <Col md={9} className="p-4 rounded wow fadeInDown" data-wow-delay="0.5s" style={{ backgroundColor: "#323438" }}>
                    <h2 className='text-center text-uppercase mb-0 orange-text'>Contact Us</h2>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label className="white-text">Full Name</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon={faUser} style={{ color: 'orange' }} /></InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleInputChange}
                                            placeholder="Enter your full name"
                                            required
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label  className="white-text">Email Address</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon={faEnvelope} style={{ color: 'orange' }} /></InputGroup.Text>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            placeholder="Enter your email"
                                            required
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label  className="white-text">Phone Number</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon={faPhone} style={{ color: 'orange' }} /></InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                            placeholder="Enter your phone number"
                                            required
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label className="white-text">Address</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: 'orange' }} /></InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleInputChange}
                                            placeholder="Enter your address"
                                            required
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3">
                            <Form.Label className="white-text">Message</Form.Label>
                            <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faComment} style={{ color: 'orange' }} /></InputGroup.Text>
                                <Form.Control
                                    as="textarea"
                                    rows={6}
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    placeholder="Enter your message"
                                    required
                                />
                            </InputGroup>
                        </Form.Group>
                        <Button className="btn-orange col-12" type="submit" >Submit</Button>

                    </Form>
                </Col>
                <Col md={3} className="text-light p-4 rounded mt-4 mt-md-0 wow fadeInUp" data-wow-delay="2s" style={{ backgroundColor: "#323438" }}>
                    <h5 className='orange-text mb-5 text-uppercase'>Contact Information</h5>
                    <p><FontAwesomeIcon icon={faPhone} style={{ color: 'orange' }} /> +260 977 456 789</p>
                    <p><FontAwesomeIcon icon={faEnvelope} style={{ color: 'orange' }} /> info@lumbeinnovations.com</p>
                    <p><FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: 'orange' }} /> 123 Main Street, Lusaka, Zambia</p>
                </Col>
            </Row>
        </Container>
    );
};

export default ContactUs;
