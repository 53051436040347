import React, { useRef, useState, useEffect, useContext } from 'react'
import { Container, Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faOilCan, faBullseye, faFlag } from '@fortawesome/free-solid-svg-icons';
import WOW from 'wowjs';

const AboutUs = () => {
    useEffect(() => {
        new WOW.WOW({
          live:false
        }).init();
          }, [])

          const cardStyle = {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: 'white',
            marginBottom: '20px',
            minHeight: '100%',
            transition: 'background-color 0.3s'
        };

    return (
        <Container className="mt-5 pt-5 " >
             <div className="container-fluid" style={{ backgroundColor: "#323438" }}>
            <h1 className="text-center text-uppercase mb-5 orange-text">About Us</h1>
            <Row className="d-flex align-items-stretch">
                <Col md={6} className="mb-4">
                    <Card className="h-100 wow fadeInLeft" data-wow-delay="3s"  style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
                        <Card.Body className="d-flex flex-column">
                            <h4 className="card-title orange-text"><FontAwesomeIcon icon={faInfoCircle} style={{ color: 'orange' }} /> About Lumbe Innovations Limited</h4>
                            <hr />
                            <p className="card-text flex-grow-1 white-text">Lumbe Innovations Limited is a dynamic and versatile company based in Zambia, dedicated to providing high-quality services and products across multiple sectors. Our mission is to deliver exceptional value to our clients through innovative solutions, outstanding service, and a commitment to excellence. With a team of experienced professionals and a robust infrastructure, we are well-equipped to meet the evolving needs of our customers and contribute to their success.</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="h-100 wow fadeInRight" data-wow-delay="2s"  style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
                        <Card.Body className="d-flex flex-column">
                            <h4 className="card-title orange-text"><FontAwesomeIcon icon={faOilCan} style={{ color: 'orange' }}/> Licensed Oil Marketing Company</h4>
                            <hr />
                            <p className="card-text flex-grow-1 white-text">Lumbe is an OMC (Oil Marketing Company) licensed by the Energy Regulation Board (ERB) to transport, sell, and store fuel products such as diesel, petrol, and kerosene. This accreditation underscores our commitment to adhering to the highest standards in the energy sector, ensuring reliable and compliant fuel solutions for our clients.</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="h-100 wow fadeInDown" data-wow-delay="0.5s"  style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
                        <Card.Body className="d-flex flex-column">
                            <h4 className="card-title orange-text"><FontAwesomeIcon icon={faBullseye} style={{ color: 'orange' }}/> Our Vision</h4>
                            <hr />
                            <p className="card-text flex-grow-1 white-text">To be a leading provider of integrated solutions that drive progress and enhance the quality of life for our clients and communities.</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} className="mb-4">
                    <Card className="h-100 wow fadeInDown" data-wow-delay="1s"  style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
                        <Card.Body className="d-flex flex-column">
                            <h4 className="card-title orange-text"><FontAwesomeIcon icon={faFlag} style={{ color: 'orange' }}/> Our Mission</h4>
                            <hr />
                            <p className="card-text flex-grow-1 white-text">To deliver superior products and services with integrity, innovation, and excellence, ensuring the utmost satisfaction of our clients.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </div>
        </Container>
    );
};

export default AboutUs;
