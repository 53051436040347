import React, { useRef, useState, useEffect, useContext } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import image from './images/13.png'; // Adjust the path based on your project structure
import imageInHeader from './images/15.png';
import WOW from 'wowjs';

const Home = () => {

    useEffect(() => {
        new WOW.WOW({
          live:false
        }).init();
          }, [])

    const sectionStyle = {
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };

      const maskStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: 'linear-gradient(45deg, #FFA500, #FFFF00, #FF0000)',
        opacity: 0.6, // Adjust opacity as needed
      };

      const textContainerStyle = {
        position: 'relative', // Ensure z-index works
        zIndex: 1, // Place text above the gradient mask
        color: '#fff', // White text color
        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Dark overlay background
        padding: '20px', // Add padding for readability
      };

      const textContainerStyleSmall = {
        position: 'relative', // Ensure z-index works
        zIndex: 1, // Place text above the gradient mask
        color: '#fff', // White text color
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark overlay background
        padding: '20px', // Add padding for readability
      };
      
    return (
        <>
        <header>
   
        <section className="view intro-2 rgba-gradient" style={sectionStyle}>
      <div className="mask" style={maskStyle}>
        <div className="container h-100 d-flex justify-content-center align-items-center">
          <div className="row flex-center pt-0 mt-0"  style={textContainerStyle}>
            <div className="col-md-12 col-lg-9 text-left text-md-left margins" >
              <div className="white-text" style={textContainerStyleSmall} >
                <h1 className="h1-responsive font-weight-bold wow fadeInLeft" data-wow-delay="0.3s">
                 Welcome To  Lumbe Innovations Limited
                </h1>
                <hr className="hr-light wow fadeInLeft" data-wow-delay="0.3s" />
                <h6 className="wow fadeInLeft" data-wow-delay="0.3s">
                  At Lumbe Innovations Limited, we pride ourselves on delivering comprehensive solutions to meet your diverse needs. Based in Zambia, we specialize in providing top-notch services and products across various sectors, ensuring quality, reliability, and efficiency. Whether you're looking for transportation services, industrial supplies, or expert consultancy, we've got you covered.
                </h6>
              </div>
            </div>
            <div className="col-md-12 col-lg-3 wow fadeInRight" data-wow-delay="0.3s">
             {/* <img src={imageInHeader} alt="" className="img-fluid" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>

</header>
{/*
        <Container className="mt-5 pt-5">
            <Row>
                <Col>
                    <h1>Welcome to Lumbe Innovations Limited</h1>
                    <p>
                        At Lumbe Innovations Limited, we pride ourselves on delivering comprehensive solutions to meet your diverse needs. Based in Zambia, we specialize in providing top-notch services and products across various sectors, ensuring quality, reliability, and efficiency. Whether you're looking for transportation services, industrial supplies, or expert consultancy, we've got you covered.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className="p-0">
                    <img src={image} alt="Lumbe Innovations" className="img-fluid w-100" />
                </Col>
            </Row>
        </Container>
        */}

        </>
    );
};

export default Home;
