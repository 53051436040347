import React, { useRef, useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';
import img5 from './images/5.png';
import tipperTruckOffloading from './images/tipper truck offloading.png';
import img8 from './images/8.png';
import trucks from './images/trucks.png';
import img11 from './images/11.png';
import img15 from './images/15.png';
import fuelTanks from './images/fuel tanks.png';
import fuelTunkerOnTheRoadWithOtherCars from './images/fuel tunker on the road with other cars.png';
import generalSuppliesNetwork from './images/general supplies network.png';
import plantGerminating from './images/plant germinating.png';
import img21 from './images/21.png';
import fertilizerInBags from './images/fertilizer in bags.png';
import WOW from 'wowjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ProductsAndServices = () => {
    useEffect(() => {
        new WOW.WOW({
          live:false
        }).init();
    }, []);
    
    const transportationImages1 = [img5, tipperTruckOffloading, img8];
    const transportationImages2 = [trucks, img11];
    const fuelImages = [img15, fuelTanks, fuelTunkerOnTheRoadWithOtherCars];
    const fertilizerImages = [plantGerminating, img21, fertilizerInBags];

    const cardStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        marginBottom: '20px',
        minHeight: '100%',
        transition: 'background-color 0.3s'
    };

    const cardHoverStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        color: 'black'
    };

    const listStyle = {
        listStyleType: 'none',
        paddingLeft: '0'
    };

    const listItemStyle = {
        marginBottom: '10px'
    };

    return (
        <Container className="mt-5 pt-5">
            <h1 className="text-center mb-5 orange-text text-uppercase">Products and Services</h1>

            <div className="container-fluid" style={{ backgroundColor: "#323438" }}>
                <div className="container">
                    <section id="blog" className="section extra-margins text-center mt-4 pb-5 pt-1 mb-5">
                        <h2 className="section-heading text-center mb-5 text-uppercase font-weight-bold pt-5 mt-5 wow fadeInDown orange-text" data-wow-delay="0.5s">Transport</h2>

                        <div className="row mb-3 wow fadeIn" data-wow-delay="0.4s" style={{ display: 'flex' }}>
    <div className="col-lg-4 col-md-12 mb-5 d-flex">
        <div className="card card-cascade narrower flex-grow-1 wow fadeInLeft " data-wow-delay="1s" style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
            <div className="view view-cascade overlay zoom">
                <Carousel
                                    nextIcon={<FontAwesomeIcon icon={faChevronRight} />}
                                    prevIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                >
                    {transportationImages1.map((image, idx) => (
                        <Carousel.Item key={idx}>
                            <img src={image} alt={`Transportation Service ${idx + 1}`} className="card-img-top" style={{ height: '200px', objectFit: 'cover' }}  />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <div className="card-body card-body-cascade d-flex flex-column">
                <h5 className="orange-text"><i className="fas fa-truck"></i> Tipper Trucks</h5>
                <p className='text-left flex-grow-1 white-text'>
                    Reliable tipper truck services for all your construction and mining needs. Our tippers can carry a 
                    variety of materials including building sand, gravel, crushed stone, topsoil, coal, and other bulk 
                    materials.
                </p>
            </div>
        </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-5 d-flex">
        <div className="card card-cascade narrower flex-grow-1 wow fadeInUp" data-wow-delay="2s" style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
            <div className="view view-cascade overlay zoom">
                <Carousel
                                    nextIcon={<FontAwesomeIcon icon={faChevronRight} />}
                                    prevIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                >
                    {transportationImages2.map((image, idx) => (
                        <Carousel.Item key={idx}>
                            <img src={image} alt={`Transportation Service ${idx + 1}`} className="card-img-top" style={{ height: '200px', objectFit: 'cover' }} />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <div className="card-body card-body-cascade d-flex flex-column">
                <h5 className="orange-text"><i className="fas fa-box"></i> Dry Cargo</h5>
                <p className='text-left flex-grow-1  white-text'>
                    Seamless and efficient transportation services using our fleet of 30-ton trucks for dry cargo. We ensure safe 
                    and timely delivery of goods both within Zambia and internationally.
                </p>
            </div>
        </div>
    </div>
    <div className="col-lg-4 col-md-6 mb-5 d-flex">
        <div className="card card-cascade narrower flex-grow-1 wow fadeInRight" data-wow-delay="3s" style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
            <div className="view view-cascade overlay zoom">
                <Carousel
                                    nextIcon={<FontAwesomeIcon icon={faChevronRight} />}
                                    prevIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                >
                    {fuelImages.map((image, idx) => (
                        <Carousel.Item key={idx}>
                            <img src={image} alt={`Fuel Service ${idx + 1}`} className="d-block w-100 card-img-top" style={{ height: '200px', objectFit: 'cover' }} />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
            <div className="card-body card-body-cascade d-flex flex-column">
                <h5 className="orange-text"><i className="fas fa-gas-pump"></i> Fuel Tankers</h5>
                <p className='text-left flex-grow-1 white-text'>
                    Providing local and international fuel transportation and delivery services across Zambia. We ensure 
                    your fuel supply reaches you efficiently and safely.
                </p>
            </div>
        </div>
    </div>
</div>

                    </section>
                </div>
            </div>

            <hr className='mt-5 mb-5'/>

            <div className="container-fluid wow fadeInDown" data-wow-delay="1s" style={{ backgroundColor: "#323438" }}>
                <div className="container pt-3">
                    <section id="about" className="section pt-2 pb-5">
                        <h1 className="text-center orange-text text-uppercase font-weight-bold mt-5 mb-3 mt-4 wow fadeIn" data-wow-delay="0.2s">General Supplies</h1>
                        <div className="line wow fadeIn" data-wow-delay="0.2s"></div>

                        <div className="row mt-5 pt-5">
                            <div className="col-lg-5 col-md-12 mb-5 wow fadeIn" data-wow-delay="0.4s">
                                <img src={generalSuppliesNetwork} className="img-fluid z-depth-1" alt="General Supplies Network"/>
                            </div>
                            <div className="col-lg-6 offset-lg-1 col-md-12 wow fadeIn" data-wow-delay="0.4s">
                                <p className="white-text mb-4" align="justify">
                                    At Lumbe Innovations Limited, we provide an extensive range of raw materials and chemicals essential for the 
                                    manufacturing, agricultural, and mining industries. Our strategically located warehousing and distribution facilities 
                                    in Lusaka enable us to efficiently serve clients across Zambia. Our robust distribution network ensures timely 
                                    delivery and consistent supply to meet your operational needs.
                                </p>
                            </div>
                        </div>

                        <Row>
                            <Col md={6} className='mt-3'>
                                <Card className="text-white" style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
                                    <Card.Body>
                                        <Card.Header>
                                            <h6 className="mb-3 text-uppercase orange-text"><i className="fas fa-mountain"></i> Mining Chemicals and Equipment</h6>
                                        </Card.Header>
                                        <ul style={listStyle}>
                                            <li style={listItemStyle}><i className="fas fa-bomb orange-text"></i> Explosives and blasting agents</li>
                                            <li style={listItemStyle}><i className="fas fa-flask orange-text"></i> Flotation reagents</li>
                                            <li style={listItemStyle}><i className="fas fa-cogs orange-text"></i> Grinding media</li>
                                            <li style={listItemStyle}><i className="fas fa-tools orange-text"></i> Drilling rigs</li>
                                            <li style={listItemStyle}><i className="fas fa-hard-hat orange-text"></i> Protective gear (helmets, gloves, etc.)</li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={6} className='mt-3'>
                                <Card className="text-white" style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
                                    <Card.Body>
                                        <Card.Header>
                                            <h6 className="mb-3 text-uppercase  orange-text"><i className="fas fa-tractor"></i> Agricultural Equipment</h6>
                                        </Card.Header>
                                        <ul style={listStyle}>
                                            <li style={listItemStyle}><i className="fas fa-seedling orange-text"></i> Tractors</li>
                                            <li style={listItemStyle}><i className="fas fa-hand-holding-water orange-text"></i> Irrigation systems</li>
                                            <li style={listItemStyle}><i className="fas fa-haykal orange-text"></i> Harvesters</li>
                                            <li style={listItemStyle}><i className="fas fa-bug orange-text"></i> Pesticides and fertilizers</li>
                                            <li style={listItemStyle}><i className="fas fa-hammer orange-text"></i> Hand tools</li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={6} className='mt-3'>
                                <Card className="text-white" style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
                                    <Card.Body>
                                        <Card.Header>
                                            <h6 className="mb-3 text-uppercase  orange-text"><i className="fas fa-industry"></i> Industrial Chemicals</h6>
                                        </Card.Header>
                                        <ul style={listStyle}>
                                            <li style={listItemStyle}><i className="fas fa-vial orange-text"></i> Acids (Sulfuric, Hydrochloric, etc.)</li>
                                            <li style={listItemStyle}><i className="fas fa-flask orange-text"></i> Solvents</li>
                                            <li style={listItemStyle}><i className="fas fa-tint orange-text"></i> Dyes and pigments</li>
                                            <li style={listItemStyle}><i className="fas fa-biohazard orange-text"></i> Cleaning agents</li>
                                            <li style={listItemStyle}><i className="fas fa-atom orange-text"></i> Catalysts</li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col md={6} className='mt-3'>
                                <Card className="text-white" style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
                                    <Card.Body>
                                        <Card.Header>
                                            <h6 className="mb-3 text-uppercase orange-text"><i className="fas fa-flask"></i> Laboratory Equipment</h6>
                                        </Card.Header>
                                        <ul style={listStyle}>
                                            <li style={listItemStyle}><i className="fas fa-microscope orange-text"></i> Microscopes</li>
                                            <li style={listItemStyle}><i className="fas fa-vials orange-text"></i> Glassware</li>
                                            <li style={listItemStyle}><i className="fas fa-thermometer-half orange-text"></i> Thermometers</li>
                                            <li style={listItemStyle}><i className="fas fa-spinner orange-text"></i> Centrifuges</li>
                                            <li style={listItemStyle}><i className="fas fa-prescription-bottle orange-text"></i> Chemical reagents</li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </section>
                </div>
            </div>

            <hr className='mt-5 mb-5'/>
<div className="container-fluid wow fadeInDown" data-wow-delay="1s" style={{ backgroundColor: "#323438" }}>

  {/* First container */}
  <div className="container pt-3">

    {/* Section About */}
    <section id="about" className="section pt-2 pb-5">

      {/* Section heading */}
      <h1 className="text-center orange-text text-uppercase font-weight-bold mt-5 mb-3 mt-4 wow fadeIn"
        data-wow-delay="0.2s">Fertilizer Sales</h1>

            <div className="line wow fadeIn" data-wow-delay="0.2s"></div>

      {/* First row */}
      <div className="row mt-5 pt-5">

        {/* First column */}
        <div className="col-lg-5 col-md-12 mb-5 wow fadeIn" data-wow-delay="0.4s">

          {/* Image */}
        <Carousel
                            nextIcon={<FontAwesomeIcon icon={faChevronRight} />}
                            prevIcon={<FontAwesomeIcon icon={faChevronLeft} />}
        >
                                {fertilizerImages.map((image, idx) => (
                                    <Carousel.Item key={idx}>
                                        <img src={image} alt={`Fertilizer ${idx + 1}`} className="d-block w-100 img-fluid z-depth-1" />
                                    </Carousel.Item>
                                ))}
                            </Carousel>

        </div>
        {/* First column */}

        {/* Second column */}
        <div className="col-lg-6 offset-lg-1 col-md-12 wow fadeIn" data-wow-delay="0.4s">

          {/* Description */}
          <p className="white-text mb-4" align="justify">
          Enhance your soil fertility and boost your crop yields with our premium fertilizers at Lumbe Innovations 
          Limited. Our range of fertilizers is designed to support the agricultural sector by helping farmers achieve 
          better productivity and healthier crops.
          </p>
        </div>
        {/* Second column */}

      </div>
      {/* First row */}

      
      <Row>
    <Col md={6} className='mt-3'>
        <Card className="text-white" style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
            <Card.Body>
                <Card.Header>
                    <h6 className="mb-3 text-uppercase orange-text"><i className="fas fa-leaf"></i> Our Top Fertilizers:</h6>
                </Card.Header>
                <ul style={listStyle}>
                    <li style={listItemStyle}>
                        <i className="fas fa-flask orange-text"></i> D Compound: Perfect for ensuring balanced nutrient distribution, D Compound fertilizer is essential 
                        for promoting robust root development and vigorous plant growth. This fertilizer is a must-have for 
                        farmers aiming to enhance their crop yields and overall farm productivity.
                    </li>
                    <li style={listItemStyle}>
                        <i className="fas fa-flask orange-text"></i> Urea: Known for its high nitrogen content, urea fertilizer is crucial for stimulating leaf growth 
                        and enhancing plant vigor. It's the ideal choice for farmers looking to maximize their crop’s 
                        potential and achieve superior results.
                    </li>
                </ul>
            </Card.Body>
        </Card>
    </Col>

    <Col md={6} className='mt-3'>
        <Card className="text-white" style={cardStyle} onMouseEnter={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.9)'} onMouseLeave={e => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.6)'}>
            <Card.Body>
                <Card.Header>
                    <h6 className="mb-3 text-uppercase orange-text"><i className="fas fa-seedling"></i> Additional Fertilizers:</h6>
                </Card.Header>
                <ul style={listStyle}>
                    <li style={listItemStyle}><i className="fas fa-flask orange-text"></i> Phosphorus Fertilizers: Crucial for root development and flower/fruit production.</li>
                    <li style={listItemStyle}><i className="fas fa-flask orange-text"></i> Potassium Fertilizers: Important for disease resistance, water retention, and overall plant health.</li>
                    <li style={listItemStyle}><i className="fas fa-seedling orange-text"></i> Organic Fertilizers: Improve soil structure and provide essential nutrients naturally.</li>
                    <li style={listItemStyle}><i className="fas fa-seedling orange-text"></i> Specialty Blends: Tailored to meet specific crop needs and soil conditions.</li>
                </ul>
            </Card.Body>
        </Card>
    </Col>
</Row>


    </section>
    {/* Section About */}

  </div>
  </div>





        </Container>
    );
};

export default ProductsAndServices;
