import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from './pages/Navbar';
import Footer from './pages/Footer';
import Home from './pages/Home';
import WhyChooseUs from './pages/WhyChooseUs';
import OurServices from './pages/OurServices';
import ProductsAndServices from './pages/ProductsAndServices';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import MainComponent from './pages/MainComponent';

const App = () => {
    return (
        <Router>
            <NavigationBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/why-choose-us" element={<WhyChooseUs />} />
                <Route path="/services" element={<OurServices />} />
                <Route path="/products" element={<ProductsAndServices />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/MainComponent" element={<MainComponent />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default App;
